import React from 'react'
import { PageComponent } from '@wap-client/constants/types'

import nodes from '@/components'

export const render = (
  components: PageComponent[],
  views: Record<string, PageComponent[]>
) => {
  if (!components || !components.length) return null

  return components.map((component) => {
    const { id, name, props, refs, children } = component

    if (name === 'view') {
      const view = views[id]

      if (view) {
        return <React.Fragment key={id}>{render(view, views)}</React.Fragment>
      }
    } else {
      const Component = nodes(name)

      if (Component) {
        if (children.length) {
          return (
            <Component title={''} desc={''} key={id} {...refs} {...props}>
              {render(children, views)}
            </Component>
          )
        } else {
          return (
            <Component
              title={''}
              desc={''}
              hasimage={false}
              key={id}
              {...refs}
              {...props}
            />
          )
        }
      }

      if (process.env.NEXT_PUBLIC_ENV === 'development') {
        console.log(`Missing component definitions (${name}): `, {
          props,
          refs,
        })
      }
    }
  })
}

export default render
