import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Section = dynamic(() => import('./base/section'))
const Image = dynamic(() => import('./base/image'))
const Video = dynamic(() => import('./base/video'))

const Header = dynamic(() => import('./widgets/header'))
const Footer = dynamic(() => import('./widgets/footer'))
const Slider = dynamic(() => import('./widgets/slider'))
const FlipCards = dynamic(() => import('./widgets/flip-cards'))
const BullBanner = dynamic(() => import('./widgets/bull-banner'))
const BullsImageBanner = dynamic(() => import('./widgets/bulls-image-banner'))
const BullsContact = dynamic(() => import('./widgets/bulls-contact'))
const Accordion = dynamic(() => import('./widgets/accordion'))
const AccordionManagment = dynamic(
  () => import('./widgets/accordion-managment')
)
const BreadCrumb = dynamic(() => import('./widgets/breadcrumb'))
const BullsForm = dynamic(() => import('./widgets/bulls-form'))
const ButtonGroups = dynamic(() => import('./widgets/button-link-groups'))
const textAndImage = dynamic(() => import('./widgets/text-and-image'))
const Brands = dynamic(() => import('./widgets/brands'))
const ProjeForm = dynamic(() => import('./widgets/proje-form'))
const LinksYearGroup = dynamic(() => import('./widgets/links-yeargroup'))

export default function getter(name: string) {
  switch (name) {
    case 'container':
      return Container
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'image':
      return Image
    case 'section':
      return Section
    case 'video':
      return Video

    case 'bulls-header':
      return Header
    case 'bulls-footer':
      return Footer
    case 'bulls-slider':
      return Slider
    case 'bulls-flip-cards':
      return FlipCards
    case 'bulls-bull-banner':
      return BullBanner
    case 'bulls-image-banner':
      return BullsImageBanner
    case 'bulls-contact':
      return BullsContact
    case 'accordion':
      return Accordion
    case 'accordion-managment':
      return AccordionManagment
    case 'breadcrumb-and-pagehead':
      return BreadCrumb
    case 'bulls-insan-kaynaklari-form':
      return BullsForm
    case 'button-link-groups':
      return ButtonGroups
    case 'text-and-image':
      return textAndImage
    case 'brands':
      return Brands
    case 'bulls-proje-basvurusu-form':
      return ProjeForm
    case 'links-yeargroup':
      return LinksYearGroup
  }
}
