import React, { useEffect } from 'react'
import type { GetServerSideProps, NextPage } from 'next'
import Head from 'next/head'
import Cookies from 'universal-cookie'
import {
  ColorPalette,
  Alternate,
  Style,
  Error,
  ErrorProps,
  Environment,
  AppContextProps,
  AppContextProvider,
} from '@wap-client/core'
import {
  configService,
  pageService,
  siteService,
  Page,
} from '@wap-client/services'
import { NodeEnv } from '@wap-client/constants/enums'

import SeoMeta from '@/core/components/seo-meta'
import render from '@/core/render'

type PageProps = AppContextProps & {
  error?: ErrorProps
}

const Page: NextPage<PageProps> = ({ error, ...context }) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === 'development') {
      // console.log(context)
    }

    if (context.language) {
      const cookie = new Cookies()

      cookie.set('language', context.language)
    }
  }, [context])

  if (error) {
    return <Error {...error} />
  }

  return (
    <React.StrictMode>
      <SeoMeta context={context} />
      <Head>
        <Alternate context={context} />
      </Head>
      <ColorPalette context={context} />
      <AppContextProvider value={context}>
        {render(context.page.layout, context.page.views)}
      </AppContextProvider>
      <Style context={context} />
    </React.StrictMode>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const cookieLanguage = context.req.cookies?.language
  const props: Partial<PageProps> = {
    container: 'browser',
    headers: context.req.headers,
    cookies: context.req.cookies,
    params: context.query,
    language: cookieLanguage,
  }

  if (context.req.headers.host) {
    const environment = new Environment()
    const host = context.req.headers.host.replace('www.', '')
    const params = context.query.pathname || []
    let pathname = typeof params === 'string' ? params : params.join('/')
    let parts = pathname.split('/')

    if (process.env.NEXT_PUBLIC_ENV === NodeEnv.Production) {
      try {
        const site = await siteService.get(environment.context, host)

        environment.update({
          cdn: 'https://minio.wapbukafamedya.com',
          environment: process.env.NEXT_PUBLIC_ENV,
          key: site.key,
          service: `${site.service}/${site.key}`,
          url: site.url,
        })

        if (parts.length) {
          props.language = parts[0] || cookieLanguage || 'en-us'
        }
      } catch (error: any) {
        return { props: { error } }
      }
    } else {
      if (parts.length) {
        environment.set('key', parts[0])

        if (parts[1]) {
          pathname = parts.slice(1, parts.length).join('/')

          props.language = parts[1]
        } else {
          pathname = ''
        }
      }
    }

    try {
      const config = await configService.get(environment.context, {
        language: String(props.language),
      })

      props.settings = config.settings
      props.themes = config.themes
      props.languages = config.languages
      props.language = config.language
      props.environment = environment.context
    } catch (error: any) {
      props.error = error
    }

    if (props.settings && props.themes) {
      try {
        let page: Page | null = null
        const previewId = context.query['previewId']

        if (previewId) {
          page = await pageService.preview(
            environment.context,
            String(previewId)
          )
        } else {
          page = await pageService.get(environment.context, {
            path: pathname || props.settings.homepage,
          })
        }

        if (page) {
          props.page = page
        }

        props.theme = props.themes.find((theme) => {
          if (page && page.theme) {
            return theme.id === page.theme
          } else {
            return theme.isDefault === true
          }
        })
      } catch (error: any) {
        props.error = error
      }
    }
  }

  return { props }
}

export default Page
